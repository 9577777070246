import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import InvistaLogo from "../images/logos/invista-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/card-img/invista-online.png'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Roda de Conversa Online sobre Investimentos</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Roda de Conversa Online sobre Investimentos (Invista como uma garota)"
        date = "Maio/2020"
        description = "Segunda roda de conversa, dessa vez online, sobre investimentos e educação financeira, em parceria com o Invista como uma garota. O evento contou com 21 participantes de diferentes instituições e teve duração de 2h."
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={InvistaLogo} alt="" className="partner-logo"/>
                <p className="partner-name">Invista como uma Garota</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
